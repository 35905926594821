/**** General ****/
$font-family: Poppins, sans-serif;

/**** App roots ****/
$assets-root: '~/assets/';
$images-root: $assets-root + 'img/';
$fonts-root: $assets-root + 'fonts/';

/**** Index Layers ****/
$layer-dropdown-item: 2;
$layer-img-close-btn: 1;

$main-color: #8c00000d;
$dark-red-1: #540011;
$dark-red-2: #73000c;
$dark-red-3: #8c0000;

$light-grey-1: #dde1e7;
$light-grey-2: #e6e6e6;
$light-grey-3: #e0e0e0;
$light-grey-4: #828282;
$light-grey-5: #ccc;
$light-grey-6: #d9d9d9;
$light-grey-7: #fbfbfb;
$light-grey-8: #ccdafc;
$dark-grey: #072446;
$grey-1: #333333;
$grey-2: #4f4f4f;
$grey-3: #4a4a4a;
$grey-4: #bdbdbd;
$grey-6: #f2f2f2;
$grey-7: #748291;
$grey-8: #f3f3f3;
$grey-9: #a2a2a2;
$dark-grey-opa-1: rgba(7, 36, 70, 0.1);
$dark-button: #303030;
$dark-common: #333333;

$black: #000;

$green-1: #219653;
$green-2: #00b0a6;
$green-3: #a5e1bf;
$green-4: #27ae60;
$green-5: #5dc674;

$blue-1: #2d9cdb;
$blue-2: #3399ff;
$blue-3: #00489a;
$blue-4: #2f80ed;
$blue-5: #209ae8;
$blue-6: #2479e5;

$light-red-1: #f9f2f2;
$light-red-2: #8c000025;
$light-red-3: #8c000034;
$light-red-4: #f4e6e6;
$light-red-5: #ec9999;
$light-red-6: #c44345;
$light-red-7: #ffcaca;
$light-red-8: #fff8f8;
$light-red-9: #f9efef;
$light-red-10: #fee4e2;
$light-red-11: #eb5757;

$gradient-red: linear-gradient(110.19deg, #c44345 4.43%, #800c2d 93.84%);
$gradient-red2: linear-gradient(110.19deg, #800c2d 4.43%, #c44345 93.84%);
$gradient-blue: linear-gradient(110.19deg, #28b6f0 4.43%, #01499b 93.84%);

$broken-white: #f9f9f9;
$negative-red: #cf0000;
$white: #ffffff;
$positive-green: #28b446;

$yellow-1: #e1aa12;
$yellow-2: #ffde81;
$yellow-3: #f2c94c;
$light-yellow-1: rgba(242, 201, 76, 0.35);

$tosca: #00b0a6;
$orange: #f2994a;
$orange-2: #f3a660;
$grey: #c4c4c4;

$iris-100: #5d5fef;
$iris-80: #7879f1;
$iris-60: #a5a6f6;

/**** Layer Level (z-index) ****/
$layer-level-pop-up: 2;
$layer-level-modal: 50;
$layer-level-error-page: 100;
$disabled-bg: #ebedf2;

/* Background Disabled */
$bg-disabled: #ebedf2;

.cursor {
  &-pointer {
    cursor: pointer;
  }
  &-help {
    cursor: help;
  }
}

.margin {
  &-bottom {
    &-5 {
      margin-bottom: 5px;
    }

    &-8 {
      margin-bottom: 8px;
    }

    &-10 {
      margin-bottom: 10px;
    }

    &-15 {
      margin-bottom: 15px;
    }

    &-17 {
      margin-bottom: 17px;
    }

    &-20 {
      margin-bottom: 20px;
    }

    &-25 {
      margin-bottom: 25px;
    }

    &-27 {
      margin-bottom: 27px;
    }

    &-30 {
      margin-bottom: 30px;
    }

    &-35 {
      margin-bottom: 35px;
    }

    &-40 {
      margin-bottom: 40px;
    }

    &-50 {
      margin-bottom: 50px;
    }

    &-70 {
      margin-bottom: 70px;
    }

    &-80 {
      margin-bottom: 80px;
    }

    &-90 {
      margin-bottom: 90px;
    }
  }

  &-top {
    &-5 {
      margin-top: 5px;
    }

    &-8 {
      margin-top: 8px;
    }

    &-10 {
      margin-top: 10px;
    }

    &-15 {
      margin-top: 15px;
    }

    &-17 {
      margin-top: 17px;
    }

    &-20 {
      margin-top: 20px;
    }

    &-25 {
      margin-top: 25px;
    }

    &-27 {
      margin-top: 27px;
    }

    &-30 {
      margin-top: 30px;
    }

    &-35 {
      margin-top: 35px;
    }

    &-40 {
      margin-top: 40px;
    }

    &-45 {
      margin-top: 45px;
    }

    &-50 {
      margin-top: 50px;
    }

    &-70 {
      margin-top: 70px;
    }

    &-80 {
      margin-top: 80px;
    }

    &-90 {
      margin-top: 90px;
    }
  }

  &-left {
    &-5 {
      margin-left: 5px;
    }

    &-8 {
      margin-left: 8px;
    }

    &-10 {
      margin-left: 10px;
    }

    &-15 {
      margin-left: 15px;
    }

    &-17 {
      margin-left: 17px;
    }

    &-20 {
      margin-left: 20px;
    }

    &-25 {
      margin-left: 25px;
    }

    &-27 {
      margin-left: 27px;
    }

    &-30 {
      margin-left: 30px;
    }

    &-35 {
      margin-left: 35px;
    }

    &-45 {
      margin-left: 45px;
    }

    &-50 {
      margin-left: 50px;
    }

    &-70 {
      margin-left: 70px;
    }

    &-80 {
      margin-left: 80px;
    }

    &-90 {
      margin-left: 90px;
    }
  }

  &-right {
    &-5 {
      margin-right: 5px;
    }

    &-8 {
      margin-right: 8px;
    }

    &-10 {
      margin-right: 10px;
    }

    &-15 {
      margin-right: 15px;
    }

    &-17 {
      margin-right: 17px;
    }

    &-20 {
      margin-right: 20px;
    }

    &-25 {
      margin-right: 25px;
    }

    &-27 {
      margin-right: 27px;
    }

    &-30 {
      margin-right: 30px;
    }

    &-35 {
      margin-right: 35px;
    }

    &-40 {
      margin-right: 40px;
    }

    &-50 {
      margin-right: 50px;
    }

    &-70 {
      margin-right: 70px;
    }

    &-80 {
      margin-right: 80px;
    }

    &-90 {
      margin-right: 90px;
    }
  }
}

.padding {
  &-bottom {
    &-8 {
      padding-bottom: 8px;
    }

    &-10 {
      padding-bottom: 10px;
    }

    &-15 {
      padding-bottom: 15px;
    }

    &-20 {
      padding-bottom: 20px;
    }

    &-25 {
      padding-bottom: 25px;
    }

    &-30 {
      padding-bottom: 30px;
    }

    &-35 {
      padding-bottom: 35px;
    }

    &-40 {
      padding-bottom: 40px;
    }

    &-50 {
      padding-bottom: 50px;
    }

    &-70 {
      padding-bottom: 70px;
    }

    &-90 {
      padding-bottom: 90px;
    }
  }

  &-top {
    &-8 {
      padding-top: 8px;
    }

    &-10 {
      padding-top: 10px;
    }

    &-15 {
      padding-top: 15px;
    }

    &-20 {
      padding-top: 20px;
    }

    &-25 {
      padding-top: 25px;
    }

    &-30 {
      padding-top: 30px;
    }

    &-35 {
      padding-top: 35px;
    }

    &-40 {
      padding-top: 40px;
    }

    &-50 {
      padding-top: 50px;
    }

    &-70 {
      padding-top: 70px;
    }

    &-90 {
      padding-top: 90px;
    }
  }

  &-left {
    &-8 {
      padding-left: 8px;
    }

    &-10 {
      padding-left: 10px;
    }

    &-15 {
      padding-left: 15px;
    }

    &-20 {
      padding-left: 20px;
    }

    &-25 {
      padding-left: 25px;
    }

    &-30 {
      padding-left: 30px;
    }

    &-35 {
      padding-left: 35px;
    }

    &-40 {
      padding-left: 40px;
    }

    &-50 {
      padding-left: 50px;
    }

    &-70 {
      padding-left: 70px;
    }

    &-90 {
      padding-left: 90px;
    }
  }

  &-right {
    &-8 {
      padding-right: 8px;
    }

    &-10 {
      padding-right: 10px;
    }

    &-15 {
      padding-right: 15px;
    }

    &-20 {
      padding-right: 20px;
    }

    &-25 {
      padding-right: 25px;
    }

    &-30 {
      padding-right: 30px;
    }

    &-35 {
      padding-right: 35px;
    }

    &-40 {
      padding-right: 40px;
    }

    &-50 {
      padding-right: 50px;
    }

    &-70 {
      padding-right: 70px;
    }

    &-90 {
      padding-right: 90px;
    }
  }
}

.text {
  &-10 {
    font-size: 10px;
  }

  &-12 {
    font-size: 12px;
  }

  &-14 {
    font-size: 14px;
  }

  &-16 {
    font-size: 16px;
  }

  &-20 {
    font-size: 20px;
  }

  &-22 {
    font-size: 22px;
  }

  &-24 {
    font-size: 24px;
  }

  &-30 {
    font-size: 30px;
  }

  &-32 {
    font-size: 32px;
  }

  &-40 {
    font-size: 40px;
  }

  &-60 {
    font-size: 60px;
  }
}

.weight {
  &-400 {
    font-weight: 400;
  }

  &-500 {
    font-weight: 500;
  }

  &-600 {
    font-weight: 600;
  }

  &-700 {
    font-weight: 700;
  }
}

.color {
  &-white {
    color: $white;
  }

  &-black {
    color: $black;
  }

  &-dark-red-1 {
    color: $dark-red-1;
  }

  &-dark-red-2 {
    color: $dark-red-2;
  }

  &-dark-red-3 {
    color: $dark-red-3;
  }

  &-dark-gray {
    color: $dark-grey;
  }

  &-grey {
    color: $grey;
  }

  &-grey-1 {
    color: $grey-1;
  }

  &-grey-2 {
    color: $grey-2;
  }

  &-grey-3 {
    color: $grey-3;
  }

  &-grey-6 {
    color: $grey-6;
  }

  &-grey-7 {
    color: $grey-7;
  }

  &-light-grey-4 {
    color: $light-grey-4;
  }

  &-light-grey-5 {
    color: $light-grey-5;
  }

  &-light-grey {
    color: #858796;
  }

  &-light-red-6 {
    color: $light-red-6;
  }

  &-common {
    color: $dark-common;
  }

  &-blue-4 {
    color: $blue-4;
  }

  &-green-4 {
    color: $green-4;
  }

  // Gradient

  &-gradient-red {
    color: $gradient-red;
  }

  &-yellow{
    color: $yellow-1;
  }
}

.outline-red {
  border: 1px solid red;
}

.border {
  &-radius {
    &-10 {
      border-radius: 10px;
    }

    &-25 {
      border-radius: 25px;
    }

    &-30 {
      border-radius: 30px;
    }

    &-35 {
      border-radius: 35px;
    }
  }
}

.rotate {
  &-90 {
    transform: rotate(-90deg);
  }

  &-180 {
    transform: rotate(-180deg);
  }

  &-270 {
    transform: rotate(90deg);
  }
}

.bg {
  &-gradient-red {
    background: $gradient-red;
  }

  &-disabled {
    opacity: 0.6;
  }
}

.w {
  &-10 {
    width: 10%;
  }

  &-15 {
    width: 15%;
  }

  &-20 {
    width: 20%;
  }

  &-25 {
    width: 25%;
  }

  &-30 {
    width: 30%;
  }

  &-40 {
    width: 40%;
  }

  &-50 {
    width: 50%;
  }

  &-60 {
    width: 60%;
  }

  &-70 {
    width: 70%;
  }

  &-80 {
    width: 80%;
  }

  &-90 {
    width: 90%;
  }

  &-100 {
    width: 100%;
  }
}

label {
  font-size: 12px;
  color: $grey-1;

  @media only screen and (max-width: 800px) {
    font-size: 10px;
  }
}
